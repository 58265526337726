import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './components/Home';
import Posts from './components/Posts';
import About from './components/About';
import Resume from './components/Resume';

class App extends Component {
    render() {
        return ( 
            <main>
                <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/posts" component={Posts} />
                    <Route path="/about" component={About} />
                    <Route path="/resume" component={Resume} />
                    <Route component={Error} />
                </Switch>
            </main>
        );
    }
}

export default App;
