import React from 'react';

import { Button } from 'reactstrap';
import { PDFExport } from '@progress/kendo-react-pdf';

import 'normalize.css/normalize.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';

import TopBar from '../TopBar';
import Footer from '../Footer';

import './Resume.css';
import resume from './contents/_data.json';

class Resume extends React.Component {
    constructor() {
        super();
        this.canvLoaded = false;
    }

    exportPDF = () => {
        this.resume.save();
    }

    main_block = (exprs, show_location) => {
        const details = exprs.details.map((item) =>
            <li>{item}</li>
        );

        return (
            <section className="blocks">
                <div className="date">
                    <span>{exprs.start}</span>
                    <span>{exprs.end}</span>
                </div>
                <div className="decorator"></div>
                <div className="details">
                    <header>
                        <h3>{exprs.title}</h3>
                        <span className="place">{exprs.place}</span>
                        { 
                            show_location === true ? 
                            <span className="location">{exprs.location}</span> : <span></span>
                        }
                    </header>
                    <div>
                        <ul className="notypelist">{details}</ul>
                    </div>
                </div>
            </section>);
    }

    education_block = (edu) => {
        return (
            <section className="blocks">
                <div className="date">
                    <span>{edu.graduate}</span>
                </div>
                <div className="decorator"></div>
                <div className="details">
                    <header>
                        <h3>{edu.degree}</h3>
                        <span className="place">{edu.school}</span>
                        <span className="location">{edu.location}</span>
                    </header>
                </div>
            </section>
        );
    }

    skill_block = (skill) => {
        const details = skill.map((s) => <li>{s}</li>);
        return (
            <ul>{details}</ul>
        );
    }

    render() {
        return (
            <div>
                    <TopBar />
                    <div className="Resume">
                        <div className="Resume-Download-Button">
                            <Button color="secondary" onClick={this.exportPDF}>Download</Button>
                        </div>
                        <PDFExport paperSize={'auto'}
                            fileName={resume.name + " Resume.pdf"}
                            title={resume.name + "s Resume"}
                            subject={resume.name + "s Resume"}
                            keywords="Resume"
                            ref={(r) => this.resume = r}>
                                <div className="Resume-PDF">
                                    <section id="main">
                                        <header id="title">
                                            <br />
                                            <h1>{resume.name}</h1>
                                            <p>{resume.profession}</p>
                                            <div style={{width:"95%"}}>
                                                <br />
                                                <span className="subtitle">{resume.bio}</span>
                                            </div>
                                        </header>

                                        <section className="main-block">
                                            <h2>Experiences</h2>
                                            { resume.experiences.map((item) => this.main_block(item, true)) }
                                        </section>
                                        
                                        { resume.projects.length > 0 ? 
                                            (
                                                <section className="main-block">
                                                    <h2>Selected Projects</h2>
                                                    {resume.projects.map((item) => this.main_block(item, false))}
                                                </section>
                                            ) : (<div></div>)
                                        }

                                        <section className="main-block concise">
                                            <h2>Education</h2>
                                            { resume.educations.map((item) => this.education_block(item)) }
                                        </section>
                                    </section>
                                    <aside id="sidebar">
                                        <div className="side-block" id="contact">
                                            <h1>Contact Info</h1>
                                            <ul>
                                                { resume.contacts.map((item) => (<li style={{fontSize: "8pt"}}>{item}</li>)) }
                                            </ul>
                                        </div>
                
                                        <div className="side-block" id="skills">
                                            <h1>Skills</h1>
                                            { resume.skills.map((item) => this.skill_block(item)) }
                                        </div>

                                        { resume.interests.length > 0 ? 
                                            (
                                                <div className="side-block" id="skills">
                                                    <h1>Interests</h1>
                                                    { resume.interests.map((item) => this.skill_block(item)) }
                                                    </div>
                                            ) : (<div></div>)
                                        }
                
                                        <div className="side-block" id="disclaimer">
                                            {resume.disclaimer}
                                        </div>
                                    </aside>
                                </div>
                        </PDFExport>
                    </div>
                <Footer />
            </div>
        );
    }
}

export default Resume;

