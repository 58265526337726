import React from 'react';
import { Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';

class Footer extends React.Component {
    render() {
        let year = new Date().getFullYear();
        if (year > 2020)
            year = "2018 - " + year;

        return (
            <footer>
                <hr />
                <Container>
                    <div class="row d-flex align-items-center">
                        <div class="col-md-7 col-lg-8">
                            <p class="text-center text-md-left">© {year} Copyright:
                                <a href="https://dengf.xyz">
                                    <strong> dengf.xyz</strong>
                                </a>
                            </p>
                        </div>
                        <div class="col-md-5 col-lg-4 ml-lg-0">
                            <div class="text-center text-md-right">
                                <ul class="list-unstyled list-inline">
                                    <li class="list-inline-item">
                                        <a href="https://www.linkedin.com/in/dengf/">
                                            <FontAwesomeIcon icon={faLinkedin} className="BadgeIcon" />
                                        </a>
                                    </li>
                                    <li class="list-inline-item">
                                        <a href="https://github.com/dengf">
                                            <FontAwesomeIcon icon={faGithub} className="BadgeIcon" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Container>
            </footer>
        );
    }
}

export default Footer;
