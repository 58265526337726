import React from 'react';
import { Container, Jumbotron } from 'reactstrap';
import desk from '../../sources/img/desk.jpg';
import bio from './contents/_data.json';

import '../../App.css';

import TopBar from '../TopBar';
import Footer from '../Footer';

class Home extends React.Component {
    render() {
        return (
            <div className="App">
                <TopBar />
                <div>
                    <Jumbotron>
                        <Container>
                            <p className="lead">{bio.details}</p>
                            <br />
                            <div>
                                <img src={desk} alt="my desk" />
                            </div>
                        </Container>
                    </Jumbotron>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Home;

