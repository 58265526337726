import React from 'react';
import { Container } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

import TopBar from '../TopBar';
import Footer from '../Footer';

import posts from './contents/_data.json';

class Posts extends React.Component {

    render_item = (item) => {
        return (
            <div>
                { ReactHtmlParser(posts[item].content) }
            </div>
        );
    }

    render() {

        return (
            <div className="App">
                <TopBar />
                <div>
                    <br />
                    <Container>
                        <div style={{textAlign: "left"}}>
                            { 
                            Object.keys(posts)
                            .sort((a, b) => -(a > b))
                            .map((item) => this.render_item(item)) }
                        </div>
                    </Container>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Posts;

